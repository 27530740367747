import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AppBar, Toolbar, IconButton, ListItem, ListItemText, Button, ListItemIcon, Menu, MenuItem, Container } from '@mui/material';
import flagIndo from '../image/flag-indo.png';
import flagInggris from '../image/flag-inggris.png';
import flagChina from '../image/flag-china.png';
import flagArab from '../image/flag-arab.png';
import LogoGunung from '../image/logo-asam-jawa.png';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArticleIcon from '@mui/icons-material/Article';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EventIcon from '@mui/icons-material/Event';
import Countdown from "react-countdown";
import LanguageIcon from '@mui/icons-material/Language'; 
import { useAnimate, stagger, motion } from "framer-motion";
// import downloadIcon from "./assets/DOWNLOAD.svg";
// import forwardIcon from "./assets/FORWARD.svg";

const Navbar = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false); 
  const theme = useTheme();
  const { t, i18n } = useTranslation("global");
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [scope, animate] = useAnimate();

  const staggerList = stagger(0.1, { startDelay: 0.25 });

  // const Completionist = () => <span>HUT RI ke 79</span>;

  // Date target (17 Agustus tahun ini)
  // const targetDate = new Date(new Date().getFullYear(), 7, 17);

  const handleClickOutside = (event) => {
    if (!scope.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (scope.current) {
      animate(
        scope.current.querySelectorAll("ul"),
        {
          width: open ? 70 : 0,
          height: open ? 100 : 0,
          opacity: open ? 1 : 0,
          position: 'absolute',
          top: '20px',
          left: '-60px',
          paddingTop: '20px',
          borderRadius: '10px'
        },
        {
          type: "spring",
          bounce: 0,
          duration: 0.4
        }
      );
      animate(
        scope.current.querySelectorAll("li"),
        open
          ? { opacity: 1, scale: 1, x: 0 }
          : { opacity: 0, scale: 0.3, x: 0 },
        {
          duration: 0.2,
          delay: open ? staggerList : 0
        }
      );
    }
  }, [open, animate, scope]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpenDrawer(open);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguageDropdownOpen(false);
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const languageMenu = (
    <Menu
      anchorEl={languageDropdownOpen}
      open={Boolean(languageDropdownOpen)}
      onClose={() => setLanguageDropdownOpen(false)}
      sx={{ mt: !isDesktop ? '-600px' && '-300px' : '-780px' }}
      anchorOrigin={{
        vertical: 'bottom', 
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem onClick={() => changeLanguage('en')}>
        <img src={flagInggris} style={{ height: 20, width: 35 }} />
        English
      </MenuItem>
      <MenuItem onClick={() => changeLanguage('id')}>
        <img src={flagIndo} style={{ height: 20, width: 35 }} />
        Indonesian
      </MenuItem>
      <MenuItem onClick={() => changeLanguage('ar')}>
        <img src={flagArab} style={{ height: 20, width: 30, paddingLeft: '4px', paddingRight: '3px' }} />
        Arabic
      </MenuItem>
      <MenuItem onClick={() => changeLanguage('zh')}>
        <img src={flagChina} style={{ height: 20, width: 20, paddingLeft: '8px', paddingRight: '7px' }} />
        Chinese
      </MenuItem>
    </Menu>
  );

  const navLinks = [
    { text: t("profil.text"), path: '/', icon: <AccountCircleIcon /> },
    { text: t("artikel-navbar.text"), path: '/artikel', icon: <ArticleIcon /> },
    { text: t("produk-navbar.text"), path: '/produk', icon: <ShoppingCartIcon /> },
    { text: t("event-navbar.text"), path: '/event', icon: <EventIcon /> },
  ];

  return (
    <>
      <AppBar position="sticky" className='app-bar' style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={LogoGunung} 
            alt="Logo" 
            style={{ height: '50px', marginLeft: '16px' }} />
          <div className="logo-text-2">
            {t("big-title-1.text")} <span className="gunung-text">{t("big-title-2.text")}</span>
          </div>
        </div>

        {!isMobile && (
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography
              id="title-header-1"
              style={{
                color: "rgb(204, 21, 21)",
                fontSize: "40px",
                textAlign: "center",
              }}
            >
              {t("asam-jawa.text")}
            </Typography>
            <Typography
              id="title-header"
              style={{
                color: "rgb(204, 21, 21)",
                fontSize: "40px",
                textAlign: "center",
                marginLeft: "10px",
              }}
            >
              {t("cap-gunung.text")}
            </Typography>
          </div>
        )}

        <Toolbar>
          {isMobile ? (
            <div ref={scope} anchor="right">
              <div>
                <motion.button style={{ 
                  position: 'relative', 
                  left: '-20px', 
                  border: 'none',
                  backgroundColor: 'yellow',
                  borderRadius: '20px' 
                  }}  
                  onClick={() => setOpen(!open)} 
                  whileTap={{ scale: 0.95 }}>
                  <MenuIcon style={{ color: 'gray', position: 'relative', top: '2px' }}/>
                </motion.button>
              </div>
              <div>
                <ul style={{ listStyleType: 'none' }}>
                  {navLinks.map((item, index) => (
                    <Button 
                      component={Link} 
                      to={item.path} 
                      onClick={() => setOpen(false)}
                      sx={{ width: '120px', background: 'linear-gradient(135deg, #a8e063 0%, #56ab2f 100%)', position: 'relative', marginTop: '20px' }}>
                      <ListItemIcon style={{ minWidth: '20px', color: 'white', position: 'relative', left: '-10px' }}>{item.icon}</ListItemIcon>
                      <motion.li style={{ 
                        color: 'white' }} 
                        key={index}>
                        {item.text}
                      </motion.li>
                    </Button>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            navLinks.map((navLink, index) => (
              <Typography key={index} variant="body1">
                <ListItem button component={Link} to={navLink.path}>
                  <ListItemIcon>{navLink.icon}</ListItemIcon>
                  <ListItemText primary={navLink.text} />
                </ListItem>
              </Typography>
            ))
          )}

          <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => setLanguageDropdownOpen(true)}>
              <LanguageIcon />
            </IconButton>
            {languageMenu}
          </div>
        </Toolbar>
      </AppBar>  

    </>
  );
};

export default Navbar;
