import React, { useEffect, useState, useRef } from 'react';
import AOS from 'aos';
import { 
    Layout, 
    Typography, 
    Row, 
    Col
} from 'antd';
import Mobile1 from '../image/mobile1.png';
import Mobile2 from '../image/mobile2.png';
import emailjs from '@emailjs/browser';
import Button from '@mui/material/Button';
import Cursor from '../image/cursor.gif';
import LogoGunung from '../image/logo-asam-jawa.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconWhatsapp from '../image/icon-whatsapp.png';
import ReactWhatsapp from 'react-whatsapp';
import { SiShopee } from 'react-icons/si';
import LogoTokped from '../image/logo-tokped.png';
import Container from '@mui/material/Container';
import IconAsam from '../image/250gr.jpg';
import IconAsam2 from '../image/150gr.jpg';
import IconAsam3 from '../image/75gr.jpg';
import IconCompany from '../image/company-asam-gunung.jpeg';
import BackgroundContent from '../image/DaunGugur2.gif';
import PlaceIcon from '@mui/icons-material/Place';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import logo1 from '../image/logo-1.png';
import logo2 from '../image/logo-2.png';
import logo3 from '../image/logo-3.png';
import logo4 from '../image/logo-4.png';
import logo5 from '../image/logo-5.png';
import Shopping from '../image/shopping2.png'; 
import '../logo-animation.css';
import 'aos/dist/aos.css';
import 'rc-footer/assets/index.css';
import { useTheme } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { ThreeCircles } from 'react-loader-spinner';
import { motion } from 'framer-motion';
import { Facebook, Instagram, Twitter, YouTube } from "@mui/icons-material";
import { FormControl, FormGroup, Grid, TextField, Box } from '@mui/material';
import Slide1 from '../image/slide10.jpeg';
import Slide2 from '../image/slide12.jpeg';
import Slide3 from '../image/slide13.jpeg';
import Slide4 from '../image/slide14.jpeg';
import Slide5 from '../image/slide15.jpeg';
// import Slide6 from '../image/slide6.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import Agen from '../image/Agen.png';
import Sales from '../image/Sales.png';

// Import Swiper styles
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import 'swiper/css/effect-cards';

// import required modules
import { EffectCards } from 'swiper/modules';



export const Home = () => {
  const { Header, Content } = Layout;

  const { t, i18n } = useTranslation("global");

  const [isLoading, setIsLoading] = useState(false);

  const [nama, setNama] = useState('');
  const [email, setEmail] = useState('');
  const [kota, setKota] = useState([]);
  const [telepon, setTelepon] = useState('');
  const [detail, setDetail] = useState('')
  const [isVisible, setIsVisible] = useState(false);
  const [isRinging, setIsRinging] = useState(false);
  const [showLogoTokopedia, setShowLogoTokopedia] = useState(false);
  const [showCall, setCall] = useState(false);
  const theme = useTheme();

 

  
  const [error, setError] = useState({
    nama: "",
    email: "",
    telepon: "",
    kota: "",
    detail: ""
  })

  const handleShoppingClick = () => {
    setShowLogoTokopedia(true);
    setTimeout(() => {
      setShowLogoTokopedia(false);
    }, 50000); // You can adjust the duration as needed
  };

  const handleCall = () => {
    setCall(true);
    setTimeout(() => {
      setCall(false);
    }, 50000)
  };

  const [isUnderlineVisible, setIsUnderlineVisible] = useState(false);

  const handleScroll = () => {
    // Adjust the scroll position threshold as needed
    const scrollThreshold = 100;
    const isScrollPastThreshold = window.scrollY > scrollThreshold;

    setIsUnderlineVisible(isScrollPastThreshold);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    const formData = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      // Set isLoading to true when sending the email
      setIsLoading(true);
  
      // Validate the form before sending the email
      const isValid = validateForm();
  
      if (isValid) {
        emailjs.sendForm('service_3ukh7m5', 'template_jk10byr', formData.current, '6l0tizyMRcFgGbKIg')
          .then((result) => {
            console.log(result.text);
            // Reset the form
            e.target.reset();
          })
          .catch((error) => {
            console.log(error.text);
          })
          .finally(() => {
            // Set isLoading to false when the email sending is complete (whether success or error)
            setIsLoading(false);
            window.location.reload();
          });
      } else {
        // Set isLoading to false when the form is not valid
        setIsLoading(false);
      }
    };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if(scrollY > 100) {
        setIsRinging(true);
      } else {
        setIsRinging(false)
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  const validateForm = () => {
    const newError = { ...error };
    let valid = true;

    if (!nama) {
      newError.nama = t("validasi-nama.text");
      valid = false;
    } else {
      newError.nama = "";
    }

    if (!email) {
      newError.email = t("validasi-email.text");
      valid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newError.email = 'Email tidak valid';
      valid = false;
    } else {
      newError.email = '';
    }

    if (!telepon) {
      newError.telepon = t("validasi-telepon.text");
      valid = false;
    } else {
      newError.telepon = '';
    }

    if (kota.length === 0) {
      newError.kota = t("validasi-kota.text");
      valid = false;
    } else {
      newError.kota = '';
    }

    if (!detail) {
      newError.detail = t("validasi-pesan.text");
      valid = false;
    } else {
      newError.detail = '';
    }

    setError(newError);

    return valid;

  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  let body = { nama, email, telepon, kota, detail };

  const onLoadData = ({ id }) => 
  new Promise ((resolve) => {
   setTimeout(() => {
     resolve(undefined);
   }, 500);
  })

  useEffect(() => {
    AOS.init({ duration: 3000 })
  }, []);


  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Warna latar belakang yang menggelapkan
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // Pastikan lebih tinggi dari elemen lain
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

    const [isShopeeVisible, setShopeeVisible] = useState(true);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setShopeeVisible((prevVisible) => !prevVisible);
      }, 4000);
  
      return () => clearInterval(interval);
    }, []);
 

  return (
    <Layout className='layout'>

     {/* <img src={logonewYear} style={{ width: isDesktop ? '30%' : '50%', top: !isDesktop ? '350px' : '400px', margin: 'auto', position: 'relative' }}  />  */}

    {/* <div style={{ display: 'flex', top: !isDesktop ? '150px' : '100px', position: 'relative', marginBottom: isDesktop ? '300px' : '300px' }}>
      <img src={Waisak} style={{ width: isDesktop ? '30%' : '80%', margin: !isDesktop ? 'auto' : 'auto', borderRadius: '20px' }}  /> 
    </div>  */}

{/* <div style={{ display: isDesktop ? 'flex' : 'block', top: !isDesktop ? '100px' : '100px', position: 'relative', marginBottom: isDesktop ? '230px' : '200px' }}>
<img src={Ramadhan} 
      width={isDesktop ? 800 : '100%'} 
      height={isDesktop ? 400 : 300}  
      style={{
        margin: !isDesktop ? 'auto' : 'auto'
      }}
      />
<img src={IdulFitri} 
      width={isDesktop ? 800 : '100%'} 
      height={isDesktop ? 400 : 300}  
      style={{
        margin: !isDesktop ? 'auto' : 'auto',
        marginTop: !isDesktop ? '50px' : 0
      }}
      />
</div> */}

      <div
         style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
         }}
      >

{isDesktop ? (
  <div
    style={{
      marginTop: "200px",
      marginBottom: '200px',
      background: '#fad492',
      height: '100px',
      width: '700px',
      borderTopRightRadius: '200px',
      borderBottomRightRadius: '200px',
    }}
  >
  </div>
) : (
  <div
    style={{
      marginTop: "60px",
      marginBottom: '100px',
      background: '#fad492',
      height: '40px',
      width: '200px',
      clipPath: 'polygon(0% 0%, 90% 50%, 0% 100%)',
    }}
  >
  </div>
)} 
        <h3 id='produk-kami' style={{ 
              textAlign: 'center',
              fontSize: isDesktop ? 30 : 18, 
              marginTop: isDesktop ? '20px' : '-34px',
              marginBottom: '2px', 
              fontFamily: 'sans-serif',
              width: isDesktop ? '20%' : '50%',
              // borderBottom: '3px solid green',
              }}>
            {t("testimoni.text")}
        </h3>

        {isDesktop ? (
  <div
    style={{
      // marginBottom: '100px',
      background: '#fad492',
      height: '100px',
      width: '700px',
      borderTopLeftRadius: '200px',
      borderBottomLeftRadius: '200px',
      marginTop: '10px',
      float: 'right',
    }}
  >
  </div>
) : (
  <div
    style={{
      // marginBottom: '700px',
      background: '#fad492',
      height: '40px',
      width: '200px',
      clipPath: 'polygon(100% 0%, 10% 50%, 100% 100%)',
      marginTop: '-38px',
      float: 'right',
    }}
  >
  </div>
)}
      </div> 

      {isDesktop ? ( 
         <>
      <div data-aos="zoom-in">
      <Content
        style={{
        padding: '0 50px',
        marginTop: '80px',
        width: '100%',
        height: '100%',
        // backgroundImage: `url(${BackgroundContent})`,
       }}
      >
        <Container sx={{ marginTop: '200px'}}>
          <img
            src={IconAsam3}
            width={500}
            height={300}
            style={{
              marginBottom: !isDesktop ? '30px' : 0,
              borderRadius: '10px',
              float: 'right',
              maxWidth: '100%', 
              borderTopRightRadius: '100px',
              borderBottomLeftRadius: '100px'
            }}  
          />
          <div style={{ display: 'flex', marginLeft: '100px', height: '60px' }}>
                <img src={LogoGunung} width={25} height={29} style={{ marginRight: '13px', paddingTop: '1px' }} />
              <Typography id='titleProduk'>
                <span
                className='description-produk'
                  style={{
                    display: 'inline-block',
                    borderBottom: `2px solid #8BC34A`,
                    transformOrigin: '0% 100%',
                    transform: `scaleX(${isUnderlineVisible ? 1 : 0})`,
                    transition: 'transform 0.3s ease-in-out', // Add smooth transition
                  }}
                >
                  {t('produk-1.text')}
                </span>
              </Typography>
            </div>
          <Typography className='description-produk' style={{ 
              textAlign: 'justify', 
              marginTop: '50px', 
              marginBottom: '60px',
              width: '500px',
              maxWidth: '100%',
              whiteSpace: 'pre-line',  // Ini untuk mempertahankan baris baru
            }} dangerouslySetInnerHTML={{ __html: t("deskripsi-1.text") }} />
          <Link to={{ pathname: `/produk` }}>
          <Button
           style={{
            backgroundColor: '#e6bf0d',
            color: 'white'
           }}
          >
            {t("button-produk.text")}
          </Button>
          </Link>
        </Container>
      </Content>
      </div>

      <div data-aos="zoom-in">
      <Content
        style={{
        padding: '0 50px',
        marginTop: '80px',
        width: '100%',
        height: '100%',
       }}
      >
        <Container sx={{ marginTop: '70px' }}>
          <img
            src={IconAsam2}
            width={500}
            height={300}
            style={{
              marginBottom: !isDesktop ? '30px' : 0,
              borderRadius: '10px',
              float: 'right',
              maxWidth: '100%', 
              borderTopRightRadius: '100px',
              borderBottomLeftRadius: '100px'
            }}  
          />
          <div style={{ display: 'flex', marginLeft: '100px', height: '60px' }}>
              <img src={LogoGunung} width={25} height={29} style={{ marginRight: '10px', paddingTop: '1px' }} />
              <Typography id='titleProduk'>
                <span
                className='description-produk'
                  style={{
                    display: 'inline-block',
                    borderBottom: `2px solid #8BC34A`,
                    transformOrigin: '0% 100%',
                    transform: `scaleX(${isUnderlineVisible ? 1 : 0})`,
                    transition: 'transform 0.3s ease-in-out', // Add smooth transition
                  }}
                >
                  {t('produk-2.text')}
                </span>
              </Typography>
            </div>
          <Typography className='description-produk' style={{ 
              textAlign: 'justify', 
              marginTop: '50px', 
              marginBottom: '60px',
              width: '500px',
              maxWidth: '100%',
              whiteSpace: 'pre-line',  // Ini untuk mempertahankan baris baru
            }} dangerouslySetInnerHTML={{ __html: t("deskripsi-2.text") }} />
          <Button
           href='/produk'
           style={{
            backgroundColor: '#e6bf0d',
            color: 'white'
           }}
          >
            {t("button-produk.text")}
          </Button>
        </Container>
      </Content>
      </div>

      <div data-aos="zoom-in">
      <Content
        style={{
        padding: '0 50px',
        marginTop: '80px',
        marginBottom: '100px',
        width: '100%',
        height: '100%',
       }}
      >
        <Container sx={{ marginTop: '70px' }}>
          <img
            src={IconAsam}
            width={500}
            height={300}
            style={{
              marginBottom: !isDesktop ? '30px' : 0,
              borderRadius: '10px',
              float: 'right',
              maxWidth: '100%', 
              borderTopRightRadius: '100px',
              borderBottomLeftRadius: '100px'
            }}  
          />
          <div style={{ display: 'flex', marginLeft: '100px', height: '60px' }}>
              <img src={LogoGunung} width={25} height={29} style={{ marginRight: '10px', paddingTop: '1px' }} />
              <Typography id='titleProduk'>
                <span
                className='description-produk'
                  style={{
                    display: 'inline-block',
                    borderBottom: `2px solid #8BC34A`,
                    transformOrigin: '0% 100%',
                    transform: `scaleX(${isUnderlineVisible ? 1 : 0})`,
                    transition: 'transform 0.3s ease-in-out', // Add smooth transition
                  }}
                >
                  {t('produk-3.text')}
                </span>
              </Typography>
            </div>
          <Typography className='description-produk' style={{ 
              textAlign: 'justify', 
              marginTop: '50px', 
              marginBottom: '60px',
              width: '500px',
              maxWidth: '100%',
              whiteSpace: 'pre-line',  // Ini untuk mempertahankan baris baru
            }} dangerouslySetInnerHTML={{ __html: t("deskripsi-3.text") }} />

          <Button
           href='/produk'
           style={{
            backgroundColor: '#e6bf0d',
            color: 'white'
           }}
          >
            {t("button-produk.text")}
          </Button>
        </Container>
      </Content>
      </div>
      </>
     ) : (
      <>
     <div data-aos="zoom-in">
      <Content
        style={{
          padding: '0 50px',
          marginTop: '80px',
          width: '100%',
          height: '100%',
          // backgroundImage: `url(${BackgroundContent})`,
        }}
      >
        <div style={{ marginTop: '-60px', textAlign: 'center', marginBottom: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={IconAsam3}
              height={300}
              style={{
                marginBottom: '1px',
                borderRadius: '10px',
                width: '350px',
                borderTopRightRadius: '100px',
                borderBottomLeftRadius: '100px',
              }}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60px' }}>
            <img src={LogoGunung} width={25} height={29} style={{ marginRight: '10px', paddingTop: '1px' }} />
            <Typography id='titleProduk'>
              <span
                style={{
                  display: 'inline-block',
                  borderBottom: `2px solid #8BC34A`,
                  transformOrigin: '0% 100%',
                  transform: `scaleX(${isUnderlineVisible ? 1 : 0})`,
                  transition: 'transform 0.3s ease-in-out', // Add smooth transition
                }}
              >
                {t('produk-1.text')}
              </span>
            </Typography>
          </div>
          <Typography className='description-produk' style={{ 
            textAlign: 'justify', 
            marginTop: '50px', 
            marginBottom: '60px',
            width: '500px',
            maxWidth: '100%',
            whiteSpace: 'pre-line',  // Ini untuk mempertahankan baris baru
            margin: '0 auto', // Center the text
          }} dangerouslySetInnerHTML={{ __html: t("deskripsi-1.text") }} />
        </div>
          <Link to={{ pathname: `/produk` }}>
              <motion.button 
               style={{
                backgroundColor: '#e6bf0d',
                color: 'white',
                width: '140px',
                height: '40px',
                border: '1px solid white',
                boxShadow: '0px 4px 8px rgba(255, 165, 0, 0.5), 0px 2px 4px rgba(255, 215, 0, 0.5)',
              }}
              whileTap={{ scale: 0.85 }}>
                {t("button-produk.text")}
              </motion.button>
          </Link>
      </Content>
    </div>

    <div data-aos="zoom-in">
      <Content
        style={{
          padding: '0 50px',
          marginTop: '150px',
          width: '100%',
          height: '100%',
        }}
      >
        <div style={{ marginTop: '70px', textAlign: 'center' , marginBottom: '20px'}}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={IconAsam2}
              height={300}
              style={{
                marginBottom: '1px',
                borderRadius: '10px',
                width: '350px',
                borderTopRightRadius: '100px',
                borderBottomLeftRadius: '100px',
              }}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60px' }}>
            <img src={LogoGunung} width={25} height={29} style={{ marginRight: '10px', paddingTop: '1px' }} />
            <Typography id='titleProduk'>
              <span
                style={{
                  display: 'inline-block',
                  borderBottom: `2px solid #8BC34A`,
                  transformOrigin: '0% 100%',
                  transform: `scaleX(${isUnderlineVisible ? 1 : 0})`,
                  transition: 'transform 0.3s ease-in-out', // Add smooth transition
                }}
              >
                {t('produk-2.text')}
              </span>
            </Typography>
          </div>
          <Typography className='description-produk' style={{ 
            textAlign: 'justify', 
            marginTop: '50px', 
            marginBottom: '60px',
            width: '500px',
            maxWidth: '100%',
            whiteSpace: 'pre-line',  // Ini untuk mempertahankan baris baru
            margin: '0 auto', // Center the text
          }} dangerouslySetInnerHTML={{ __html: t("deskripsi-2.text") }} />
        </div>
        <Link to={{ pathname: `/produk` }}>
              <motion.button 
               style={{
                backgroundColor: '#e6bf0d',
                color: 'white',
                width: '140px',
                height: '40px',
                border: '1px solid white',
                boxShadow: '0px 4px 8px rgba(255, 165, 0, 0.5), 0px 2px 4px rgba(255, 215, 0, 0.5)',
              }}
              whileTap={{ scale: 0.85 }}>
                {t("button-produk.text")}
              </motion.button>
          </Link>
      </Content>
    </div>

    <div data-aos="zoom-in">
      <Content
        style={{
          padding: '0 50px',
          marginTop: '150px',
          marginBottom: '100px',
          width: '100%',
          height: '100%',
        }}
      >
        <div style={{ marginTop: '70px', display: 'flex', justifyContent: 'center' }}>
          <div style={{ margin: 'auto' }}>
            <img
              src={IconAsam}
              height={300}
              style={{
                marginBottom:'1px',
                borderRadius: '10px',
                width: '350px',
                borderTopRightRadius: '100px',
                borderBottomLeftRadius: '100px',
              }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60px' }}>
          <img src={LogoGunung} width={25} height={29} style={{ marginRight: '10px', paddingTop: '1px' }} />
          <Typography id='titleProduk'>
            <span
              style={{
                display: 'inline-block',
                borderBottom: `2px solid #8BC34A`,
                transformOrigin: '0% 100%',
                transform: `scaleX(${isUnderlineVisible ? 1 : 0})`,
                transition: 'transform 0.3s ease-in-out', // Add smooth transition
              }}
            >
              {t('produk-3.text')}
            </span>
          </Typography>
        </div>
        <Typography className='description-produk' style={{ 
          textAlign: 'justify', 
          marginTop: '50px', 
          marginBottom: '30px',
          width: '500px',
          maxWidth: '100%',
          whiteSpace: 'pre-line',  // Ini untuk mempertahankan baris baru
        }} dangerouslySetInnerHTML={{ __html: t("deskripsi-3.text") }} />

          <Link to={{ pathname: `/produk` }}>
              <motion.button 
               style={{
                backgroundColor: '#e6bf0d',
                color: 'white',
                width: '140px',
                height: '40px',
                border: '1px solid white',
                boxShadow: '0px 4px 8px rgba(255, 165, 0, 0.5), 0px 2px 4px rgba(255, 215, 0, 0.5)',
              }}
              whileTap={{ scale: 0.85 }}>
                {t("button-produk.text")}
              </motion.button>
          </Link>
      </Content>
    </div>
      </>
     )}

      <div style={{
            marginTop: '80px',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${BackgroundContent})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '80% 100%',
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '-50px',
              height: '100%',
            }}>

              {isDesktop ? (
                  <div
                    style={{
                      marginTop: "200px",
                      marginBottom: '200px',
                      background: '#fad492',
                      height: '100px',
                      width: '700px',
                      borderTopRightRadius: '200px',
                      borderBottomRightRadius: '200px',
                    }}
                  >
                  </div>
                ) : (
                  <div
                    style={{
                      marginTop: "60px",
                      marginBottom: '100px',
                      background: '#fad492',
                      height: '40px',
                      width: '200px',
                      clipPath: 'polygon(0% 0%, 90% 50%, 0% 100%)',
                    }}
                  >
                  </div>
                )} 
                        <h3 id='produk-kami' style={{ 
                              textAlign: 'center',
                              fontSize: isDesktop ? 30 : 18, 
                              marginTop: isDesktop ? '20px' : '-34px',
                              marginBottom: '2px', 
                              fontFamily: 'sans-serif',
                              width: isDesktop ? '20%' : '50%',
                              // borderBottom: '3px solid green',
                              }}>
                            {t("about.text")}
                        </h3>

                        {isDesktop ? (
                  <div
                    style={{
                      // marginBottom: '100px',
                      background: '#fad492',
                      height: '100px',
                      width: '700px',
                      borderTopLeftRadius: '200px',
                      borderBottomLeftRadius: '200px',
                      marginTop: '10px',
                      float: 'right',
                    }}
                  >
                  </div>
                ) : (
                  <div
                    style={{
                      // marginBottom: '700px',
                      background: '#fad492',
                      height: '40px',
                      width: '200px',
                      clipPath: 'polygon(100% 0%, 10% 50%, 100% 100%)',
                      marginTop: '-38px',
                      float: 'right',
                    }}
                  >
                  </div>
                )}
            </div>
            <div style={{ marginTop: '100px', textAlign: 'center' }}>
              <img
                src={IconCompany}
                height={!isDesktop ? 300 : 700}
                style={{
                  borderRadius: '10px',
                  width: '90%', // Tambahkan ini
                  margin: '0 auto', // Tambahkan ini
                }}
              />
              <Typography className='description-produk'
              style={{ 
                textAlign: 'justify',
                paddingTop: isDesktop ? '70px' : '70px', 
                paddingBottom: isDesktop ? '140px' : '100px', 
                width: '90%', 
                margin: '0 auto',
                whiteSpace: 'pre-line', 
              }}
                dangerouslySetInnerHTML={{ __html: t("list-company.text")}}
              />
            </div>
          </div>


      <div
         style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
         }}
      >
        {isDesktop ? (
          <div
            style={{
              marginTop: "200px",
              marginBottom: '200px',
              background: '#fad492',
              height: '100px',
              width: '700px',
              borderTopRightRadius: '200px',
              borderBottomRightRadius: '200px',
            }}
          >
          </div>
        ) : (
          <div
            style={{
              marginTop: "60px",
              marginBottom: '100px',
              background: '#fad492',
              height: '40px',
              width: '200px',
              clipPath: 'polygon(0% 0%, 90% 50%, 0% 100%)',
            }}
          >
          </div>
        )} 
                <h3 id='produk-kami' style={{ 
                      textAlign: 'center',
                      fontSize: isDesktop ? 30 : 18, 
                      marginTop: isDesktop ? '20px' : '-34px',
                      marginBottom: '2px', 
                      fontFamily: 'sans-serif',
                      width: isDesktop ? '20%' : '50%',
                      // borderBottom: '3px solid green',
                      }}>
                    {t("hub.text")}
                </h3>

                {isDesktop ? (
          <div
            style={{
              // marginBottom: '100px',
              background: '#fad492',
              height: '100px',
              width: '700px',
              borderTopLeftRadius: '200px',
              borderBottomLeftRadius: '200px',
              marginTop: '10px',
              float: 'right',
            }}
          >
          </div>
        ) : (
          <div
            style={{
              // marginBottom: '700px',
              background: '#fad492',
              height: '40px',
              width: '200px',
              clipPath: 'polygon(100% 0%, 10% 50%, 100% 100%)',
              marginTop: '-38px',
              float: 'right',
            }}
          >
          </div>
        )}
      </div>  

<Grid>
<Grid container spacing={2} justifyContent="center" alignItems="center">
  <Grid item xs={12} sm={isDesktop ? 6 : 12} md={isDesktop ? 3 : 6} lg={!isDesktop ? 3 : 0} xl={!isDesktop ? 3 : 0} marginTop={!isDesktop ? '-60px' : 0} marginLeft={isDesktop ? 55 : 0}>
    <Container sx={{ mt: isDesktop ? 20 : 10, width: '70%', display: isDesktop ? 'flex' : 'block', position: !isDesktop ? 'relative' : 'static', left: !isDesktop ? '42px' : 'auto' }}>
      {isLoading && (
        <div style={overlayStyle}>
          <ThreeCircles color="#FFA500" />
        </div>
      )}
      <Grid display={'flex'} justifyContent="center" alignItems="center" style={{ left: !isDesktop ? '-30px' : '-450px', position: 'relative' }}>
        <Grid item>
          <img
            src={Mobile1}
            style={{
              height: !isDesktop ? '250px' : '400px',
              width: !isDesktop ? '140px' : '200px',
              transform: 'rotate(-5deg)',
              position: 'relative',
              left: isDesktop ? '-100px' : 'auto',
              margin: 'auto',
            }}
          />
        </Grid>
        <Grid item>
          <img
            src={Mobile2}
            style={{
              height: !isDesktop ? '250px' : '400px',
              width: !isDesktop ? '140px' : '200px',
              transform: 'rotate(5deg)',
              position: 'relative',
              left: isDesktop ? '-100px' : 'auto',
              margin: 'auto',
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems={isDesktop ? 'flex-start' : 'center'} style={{ width: '100%', position: 'relative', left: !isDesktop ? '-15px' : '-400px', top: isDesktop ? 0 : '90px' }}>
        <Grid item style={{ width: '300px', position: 'relative', left: !isDesktop ? '-75px' : '10px' }}>
          <Typography id='title-info' style={{ textAlign: 'center', fontSize: '20px', color: '#e49a1b' }}>
            {t("title-interest.text")}
          </Typography>
        </Grid>
        <Grid item style={{ height: '200px', width: '350px', paddingTop: !isDesktop ? '50px' : '40px', position: 'relative', left: !isDesktop ? '-78px' : 'auto' }}>
          <Typography id='deskripsi-info' style={{ textAlign: 'center' }}>
            {t("deskripsi-interest.text")}
          </Typography>
        </Grid>
        <Grid item style={{ position: 'relative', top: !isDesktop ? '130px' : '100px', left: isDesktop ? '180px' : '-70px' }}>
          <a href='/form' target='_blank'>
            <img src={Cursor} style={{ height: '60px', width: '100px', position: 'absolute', left: '50%', transform: 'translateX(-50%)', zIndex: 2, top: '-40px', cursor: 'pointer' }} />
            <Button
              target='_blank'
              style={{
                position: 'absolute',
                left: '50%',
                color: 'white',
                transform: 'translateX(-50%)',
                bottom: '10px',
                backgroundColor: '#a8c9a6',
                cursor: 'pointer',
                fontSize: '13px',
                zIndex: 1,
                width: '150px',
                height: '50px'
              }}
            >
              {t("pesan-form.text")} !
            </Button>
          </a>
        </Grid>
      </Grid>
    </Container>
  </Grid>
</Grid>

<Grid>
   <Typography
        style={{
          textAlign: 'center',
          paddingBottom: 20,
          fontSize: 20,
          fontFamily: 'monospace',
          position: 'relative',
          top: '400px',
        }}
      >
        {t("customer.text")}
      </Typography>
        <Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards]}
        className='mySwiper'
        style={{
          height: !isDesktop ? '340px' : "500px", 
          width: !isDesktop ? "220px" : "450px",
          position: 'relative',
          top: !isDesktop ? '420px' : "480px",
        }}
        >
        <SwiperSlide >
          <img style={{ height: !isDesktop ? '330px' : "490px", width: !isDesktop ? "200px" : '390px' }} src={Slide1} />
        </SwiperSlide>
        <SwiperSlide >
          <img style={{ height: !isDesktop ? '330px' : "490px", width: !isDesktop ? "200px" : '390px' }} src={Slide2} />
        </SwiperSlide>
        <SwiperSlide >
          <img style={{ height: !isDesktop ? '330px' : "490px", width: !isDesktop ? "200px" : '390px' }} src={Slide3} />
        </SwiperSlide>
        <SwiperSlide >
          <img style={{ height: !isDesktop ? '330px' : "490px", width: !isDesktop ? "200px" : '390px' }} src={Slide4} />
        </SwiperSlide>
        <SwiperSlide >
          <img style={{ height: !isDesktop ? '330px' : "490px", width: !isDesktop ? "200px" : '390px' }} src={Slide5} />
        </SwiperSlide>
        </Swiper>
        </Grid>



    <Grid item xs={12} sm={6} md={5}>
    <Container sx={{ mt: isDesktop ? 80 : 80, width: '100%' }}>
      <Typography
        style={{
          textAlign: 'center',
          paddingBottom: 20,
          fontSize: 20,
          fontFamily: 'monospace',
        }}
      >
        {t("lokasi.text")}
      </Typography>
      <div
        style={{
          position: 'relative',
          paddingTop: '75%', // 4:3 aspect ratio
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.804606110058!2d112.68640387582502!3d-7.263064871361731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fe98e5cfd91b%3A0xb0abc40d9731b273!2sJl.%20Satelit%20Utara%20IV%20No.29%2C%20Tanjungsari%2C%20Surabaya%2C%20Jawa%20Timur%2060187!5e0!3m2!1sen!2sid!4v1701221504919!5m2!1sen!2sid" 
          width="100%"
          height="100%"
          style={{ position: 'absolute', top: 0, left: 0, borderRadius: 10 }}
          allowFullScreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </Container>
  </Grid>
</Grid>

      <div
         style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          marginTop: !isDesktop ? "100px" : 0
         }}
      >
        {isDesktop ? (
          <div
            style={{
              marginTop: "200px",
              marginBottom: '200px',
              background: '#fad492',
              height: '100px',
              width: '700px',
              borderTopRightRadius: '200px',
              borderBottomRightRadius: '200px',
            }}
          >
          </div>
        ) : (
          <div
            style={{
              marginTop: "60px",
              marginBottom: '100px',
              background: '#fad492',
              height: '40px',
              width: '200px',
              clipPath: 'polygon(0% 0%, 90% 50%, 0% 100%)',
            }}
          >
          </div>
        )} 
                <h3 id='produk-kami' style={{ 
                      textAlign: 'center',
                      fontSize: isDesktop ? 30 : 18, 
                      marginTop: isDesktop ? '20px' : '-34px',
                      marginBottom: '2px', 
                      fontFamily: 'sans-serif',
                      width: isDesktop ? '20%' : '50%',
                      // borderBottom: '3px solid green',
                      }}>
                    {t("lisensi.text")}
                </h3>

                {isDesktop ? (
          <div
            style={{
              // marginBottom: '100px',
              background: '#fad492',
              height: '100px',
              width: '700px',
              borderTopLeftRadius: '200px',
              borderBottomLeftRadius: '200px',
              marginTop: '10px',
              float: 'right',
            }}
          >
          </div>
        ) : (
          <div
            style={{
              // marginBottom: '700px',
              background: '#fad492',
              height: '40px',
              width: '200px',
              clipPath: 'polygon(100% 0%, 10% 50%, 100% 100%)',
              marginTop: '-38px',
              float: 'right',
            }}
          >
          </div>
        )}
      </div>

<div className="scroll-container" style={{ marginTop: isDesktop ? '60px' : '-20px', marginBottom: '100px' }}>
  <div className="scroll-content">
    <div className="scroll-item" style={{ marginTop: !isDesktop ? '19px' : 0 }}>
      <img src={logo1} width={isDesktop ? 150 : 100} height={isDesktop ? 150 : 100}  alt="Logo 1" />
    </div>
    <div className="scroll-item">
      <img src={logo2} width={isDesktop ? 150 : 130} height={isDesktop ? 150 : 130} alt="Logo 2" />
    </div>
    <div className="scroll-item">
      <img src={logo3} width={isDesktop ? 150 : 130} height={isDesktop ? 150 : 130} alt="Logo 3" />
    </div>
    <div className="scroll-item">
      <img src={logo4} width={isDesktop ? 150 : 130} height={isDesktop ? 150 : 130} alt="Logo 4" />
    </div>
    <div className="scroll-item">
      <img src={logo5} width={isDesktop ? 150 :130} height={isDesktop ? 150 : 130} alt="Logo 5" />
    </div>
  </div>
</div>



      <div
        style={{
          position: 'fixed',
          bottom: '10px',
          left: isDesktop ? '15px' : '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <span
          onClick={handleShoppingClick}
          style={{
            position: 'absolute',
            cursor: 'pointer',
            zIndex: 1,
            top: '45%',
            left: '18%',
            transform: 'translate(-50%, -50%)',
            fontFamily: 'sans-serif',
            fontSize: '14px',
            color: 'white',
            backgroundColor: 'rgba(255, 0, 0, 0.7)',
            padding: '5px 10px',
            borderRadius: '5px',
            animation: 'floatText 2s infinite',
          }}
        >
          Shop
        </span>
        <img
          src={Shopping}
          style={{
            height: '90px',
            width: '90px',
            cursor: 'pointer',
          }}
          onClick={handleShoppingClick}
        />
        {showLogoTokopedia && (
          <a id='shopee' href='https://shopee.co.id/tamarindo_group?shopCollection=243096934#product_list' target='_blank'>
          <SiShopee
            style={{
              color: '#e6282b',
              height: '40px',
              width: '40px',
              position: 'absolute',
              bottom: '190px', // Adjust the position as needed
              left: isDesktop ? '26px' : '26px',
              zIndex: '9999', // Ensure the LogoTokopedia is below the Shopping image
            }}
          />
          </a>
        )}
        {showLogoTokopedia && (
          <a id='tokopedia' href='https://www.tokopedia.com/bjayamakmur' target='_blank'>
          <img
            src={LogoTokped}
            style={{
              height: '53px',
              width: '55px',
              position: 'absolute',
              bottom: '100px', // Adjust the position as needed
              left: isDesktop ? '15px' : '16px',
              zIndex: '9999', // Ensure the LogoTokopedia is below the Shopping image
            }}
          />
          </a>
        )}
      </div>
            <div
            style={{
              position: 'fixed',
              bottom: '10px',
              right: !isDesktop ? '20px' : '20px', 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
              <img onClick={handleCall} src={IconWhatsapp} 
                  style={{
                    maxWidth: '100%',
                    width: '70px',
                    height: '70px',
                    position: 'fixed',
                    bottom: '20px',
                    right: !isDesktop ? '20px' : '20px',
                    zIndex: '999',
                    cursor: 'pointer', // Pastikan kursor berubah saat mouse berada di atas ikon
                    pointerEvents: 'auto' 
                  }} 
                />
                {showCall && (
                  <ReactWhatsapp id="icon-whatsapp" style={{ 
                    width: 0,
                    height: 0,
                    cursor: 'pointer'
                    }} 
                    number="+6282160904267" 
                    message={t("agen.text")}> 
                  <img
                    id='wa-agen'
                    src={Agen}
                    style={{
                      height: '65px',
                      width: '60px',
                      position: 'absolute',
                      top: '-150px',
                      right: !isDesktop ? '5px' : '20px',
                    }}
                  />
                  </ReactWhatsapp>
                 )}
                {showCall && (
                  <ReactWhatsapp id="icon-whatsapp" style={{ 
                    width: 0,
                    height: 0,
                    cursor: 'pointer'
                  }} 
                  number="+6282189018400" 
                  message={t("sales.text")}> 
                  <img
                    id='wa-sales'
                    src={Sales}
                    style={{
                      color: '#e6282b',
                      height: '65px',
                      width: '60px',
                      position: 'absolute',
                      bottom: '180px', // Adjust the position as needed
                      right: !isDesktop ? '5px' : '20px',
                      zIndex: '4', // Ensure the LogoTokopedia is below the Shopping image
                    }}
                  />
                  </ReactWhatsapp>
                )}
            </div>

        <Box
        component="footer"
        id="footer"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          [theme.breakpoints.up("sm")]: {
            p: 6,
            flexDirection: "row",
            textAlign: "left",
          },
        }}
      >
        <Grid item xs={12} sm={3}>
          <div className="logo-container">
            <img
              src={LogoGunung}
              width={200}
              height={200}
              className="logo-container"
              style={{
                height: "auto",
                maxWidth: "100%",
              }}
            />
            {isDesktop ? (
              <Grid>
                <div className="logo-text">
                  {t("big-title-1.text")} <span className="gunung-text">{t("big-title-2.text")}</span>
                </div>
              </Grid>
            ) : null}
          </div>
        </Grid>
        <Container maxWidth="lg">
          <Grid container spacing={5} justifyContent="flex-end">
            <Grid item xs={12} sm={3}>
              <Typography
                style={{
                  paddingBottom: "10px",
                  fontWeight: "bold",
                }}
                variant="h6"
                color="text.primary"
                gutterBottom
              >
                 {t("tentang.text")}
                <hr />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                 {t("list-tentang.text")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                variant="h6"
                style={{
                  paddingBottom: "10px",
                  fontWeight: "bold",
                }}
                color="text.primary"
                gutterBottom
              >
                 {t("title-kontak.text")}
                <hr />
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ paddingTop: "9px" }}
              >
                <PlaceIcon
                  style={{
                    animation: isRinging
                      ? "ringing 1s infinite alternate"
                      : "none",
                  }}
                />
                <div style={{ width: "100%" }}>
                  <Typography
                    style={{
                      textAlign: !isDesktop ? "center" : "left",
                    }}
                  >
                    {t("detail-kontak.text")}
                  </Typography>
                </div>
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ paddingTop: "30px" }}
              >
                <MailIcon
                  style={{
                    animation: isRinging
                      ? "ringing 1s infinite alternate"
                      : "none",
                  }}
                />
                <Typography
                  style={{
                    marginTop: "-10px",
                    paddingTop: "9px",
                  }}
                >
                  <span
                    style={{
                      paddingRight: "5px",
                      paddingLeft: "5px",
                    }}
                  >
                    tamarindogroup3@gmail.com
                  </span>
                  <br />
                  <span
                    style={{
                      paddingLeft: isDesktop ? "5px" : 0,
                    }}
                  >
                    {t("or.text")}
                  </span>
                  <br />
                  <span
                    style={{
                      paddingRight: "10px",
                      paddingLeft: "5px",
                    }}
                  >
                    tamarindotbn@gmail.com
                  </span>
                </Typography>
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ paddingTop: "30px" }}
              >
                <PhoneIcon
                  style={{
                    marginRight: "5px",
                    animation: isRinging
                      ? "ringing 1s infinite alternate"
                      : "none",
                  }}
                />
                <Typography>
                  <span
                    style={{
                      paddingRight: "10px",
                      paddingLeft: "5px",
                    }}
                  >
                    081133388188
                  </span>
                </Typography>
              </Typography>
              <Typography
                style={{
                  marginTop: "20px",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {t("daftar-agen.text")}
                </span>
                <br />
                <span>081317779733</span>
              </Typography>
              <Typography
                style={{
                  marginTop: "20px",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {t("support.text")}
                </span>
                <br />
                <span>
                  <a
                    href="https://www.instagram.com/asamjawagunung/?hl=en"
                    style={{
                      color: "blue",
                    }}
                  >
                    @asamjawagunung
                  </a>
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                variant="h6"
                color="text.primary"
                style={{
                  paddingBottom: "10px",
                  fontWeight: "bold",
                }}
                gutterBottom
              >
                {t("follow.text")}
                <hr />
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ paddingTop: "9px" }}
              >
                <a href='https://www.facebook.com/asamjawacapgunung/'>
                <Facebook
                  style={{
                    color: "#4267B2",
                    fontSize: "30px",
                    marginRight: isDesktop ? "10px" : "30px",
                  }}
                />
                </a>
                <a href='https://twitter.com/Bintang77542150/status/994067149739188224'>
                <Twitter
                  style={{
                    color: "#1DA1F2",
                    fontSize: "30px",
                    marginRight: isDesktop ? "10px" : "30px",
                  }}
                />
                </a>
                <a href="https://www.instagram.com/asamjawagunung/"  >
                <Instagram
                  style={{
                    color: "#C13584",
                    fontSize: "30px",
                    marginRight: isDesktop ? "10px" : "30px",
                  }}
                />
                </a>
                <a href='https://www.youtube.com/channel/UCBkmg3iBjBEan-wk2pMrzXg'>
                <YouTube
                  style={{
                    color: "#FF0000",
                    fontSize: "30px",
                  }}
                />
                </a>
              </Typography>
              <Typography
                style={{
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "5px",
                  }}
                >
                  {t("stay.text")}
                </Typography>
                <Typography>
                  {t("list-stay.text")}
                </Typography>
              </Typography>
              <Typography>
                © {new Date().getFullYear()} {t("title-footer.text")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

    </Layout>
  );
};
