import React, { useState, useEffect } from "react";
import {
  Button,
  Layout,
  Carousel,
} from "antd";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TypeAnimation } from "react-type-animation";
import Grid from "@mui/material/Grid";
import Navbar from "./navbar";
import AnimasiProduk from '../image/animasi-header-produk-2.gif'; 
import AnimasiProduk2 from '../image/animasi-header-produk.gif'; 
import AnimasiAsam from '../video/animasi-asam-jawa.mp4';
import CarouselAsam from "../image/ASAM-JAWA-(PRODUK)/100 Gr DAGING  KEMASAN EXPORT/DSCF0028.jpg";
import CarouselAsam2 from "../image/ASAM-JAWA-(PRODUK)/1 Kg DAGING/DSCF0062.jpg";
import CarouselAsam3 from "../image/ASAM-JAWA-(PRODUK)/75 Gr DAGING/DSCF0098.jpg";
import IconAsam from "../image/Candlenut.png";
import IconAsam2 from "../image/cinnamon.png";
import IconAsam3 from "../image/cracker.png";
import Buble from "../image/speech-bubble.png";
import { Typography } from "@mui/material";

export const HeaderComponent = () => {
  const { Header, Content } = Layout;

  const theme = useTheme();

  const { t, i18n } = useTranslation("global");

  const [typewriterText, setTypewriterText] = useState(t("typewriter.text"));

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  console.log("ganti", typewriterText);

  useEffect(() => {
    changeLanguage(i18n.language);
    setTypewriterText(t("typewriter.text"));
  }, [i18n.language]);

  // const SnowFall = () => {
  //   return (
  //     <div className="snowfall">
  //     {[...Array(50)].map((_, index) => (
  //       <div
  //         key={index}
  //         className="snowflake"
  //         style={{
  //           top: `${Math.random() * 100}vh`,
  //           left: `${Math.random() * 100}vw`,
  //           animationDelay: `-${index * 0.1}s`, // Menambahkan keterlambatan unik
  //         }}
  //       >
  //         {[...Array(6)].map((_, subIndex) => (
  //           <span key={subIndex} className="snowflake-particle"></span>
  //         ))}
  //       </div>
  //     ))}
  //   </div>
  //   );
  // };

  // const snowfallStyle = {
  //   position: "absolute",
  //   top: "0",
  //   left: "0",
  //   width: "100%",
  //   height: "100%",
  //   pointerEvents: "none",
  // };

  const headerContentStyle = {
    position: "relative",
    marginTop: isDesktop ? "-200px" : "-250px",
    left: "0px",
    top: "0px",
    display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    padding: "20px",
    // borderBottomRightRadius: "50% 20px", // Border bottom right radius
    // borderBottomLeftRadius: "50% 20px", // Border bottom left radius
    // background: `
    //   linear-gradient(to bottom, #ffe4b5, #d2b48c),
    //   repeating-linear-gradient(45deg, transparent, transparent 10px, #d2b48c 10px, #d2b48c 20px)
    // `,
    overflow: "hidden",
  };



  return (
    <>
      <Navbar />

      {/* <SnowFall /> */}

      <Header
        style={{
          // backgroundColor: "Window",
          paddingLeft: 0,
          paddingRight: 0,
          height: !isDesktop ? "300px" : "700px",
          overflow: "hidden",
          // borderBottomLeftRadius: '60px',  
          // borderBottomRightRadius: '60px',
          position: "relative",
          backgroundColor: 'white'
        //   background: `
        //   linear-gradient(to bottom, #ffe4b5, #d2b48c),
        //   repeating-linear-gradient(45deg, transparent, transparent 10px, #d2b48c 10px, #d2b48c 20px)
        // `,
        }}
      >
        {/* <Carousel autoplay style={{ height: "800px" }}>
          <div>
            <img src={CarouselAsam} style={contentStyle} alt="Carousel 1" />
          </div>
          <div>
            <img src={CarouselAsam2} style={contentStyle} alt="Carousel 2" />
          </div>
          <div>
            <img src={CarouselAsam3} style={contentStyle} alt="Carousel 3" />
          </div>
        </Carousel> */}
        

        <div style={headerContentStyle}>
          {/* <TypeAnimation
            sequence={[
              `${typewriterText}`,
              1000,
              "",
            ]}
            speed={50}
            style={{
              marginTop: "300px",
              marginBottom: "40px",
              fontSize: isDesktop ? 25 : 19,
              color: "black",
              fontFamily: "Merriweather",
            }}
            repeat={Infinity}
          /> */}

<Grid spacing={2} sm={12} style={{ marginTop: "200px", display:'flex'}}>
  <Grid sx={4} sm={6} style={{ marginTop: isDesktop ? '100px' : '60px', width: !isDesktop ? '620px' : '400px', marginLeft: isDesktop ? '100px' : 0 }}>
    <Typography id="text-header" sx={{ fontSize: !isDesktop ? '13px' : '19px', width: !isDesktop ? '190px' : '600px'}}>
       {t('text-header.text')}
    </Typography>
     <TypeAnimation
            sequence={[
              `${typewriterText}`,
              1000,
              "",
            ]}
            speed={50}
            className="type-text"
            style={{
              fontSize: isDesktop ? 25 : 19,
            }}
            repeat={Infinity}
          />
          <br />
    <Button
      id="button-header"
      href="#info"
      style={{
        marginTop: "20px",
        // fontFamily: "cursive",
        borderRadius: "20px",
        fontSize: !isDesktop ? '12px' : '16px',
        color: "white",
        background: "orange",
        width: !isDesktop ? "120px" : "150px",
        height: !isDesktop ? "30px" : "40px",
      }}
    >
      {t("button-header.text")}
    </Button>
    {/* <Grid mt={1} ml={isDesktop ? 20 : -26}>
    <div class="image-container">
      <img  src={Buble} height={200} width={250} />
      <div className="centered-text" style={{ marginLeft: isDesktop ? '52%' : '50%', marginTop: isDesktop ? 0 : '-4px'}}>{t("produk-lainnya.text")}</div>
    </div>
    </Grid> */}
    {/* <Grid xs={12} mt={-10} sm={12} style={{ textAlign: "center", display: 'flex', flexDirection: isDesktop ? 'row' : 'column' }}>
            <Grid item xs={4} sm={2} style={{ textAlign: "center", marginLeft: isDesktop ? 0 : '-450px' }}>
              <img src={IconAsam} style={{height: isDesktop ? '190px' : '160px', width: isDesktop ? '190px' : '160px', borderRadius: '20px', zIndex: '2'}} alt="Icon 1" />
            </Grid>
            <Grid item xs={4} sm={1} style={{ textAlign: "center", marginLeft: isDesktop ? '25px' : '-200px' }}>
              <img src={IconAsam2} style={{height: '190px', width: '190px', borderRadius: '20px', zIndex: '2'}} alt="Icon 2" />
            </Grid>
            <Grid item xs={2} sm={0} style={{ textAlign: "center", marginLeft: isDesktop ? '25px' : '-450px' }}>
              <img src={IconAsam3} style={{height: '190px', width: '190px', borderRadius: '20px', zIndex: '2'}} alt="Icon 3" />
            </Grid>
      </Grid> */}
  </Grid>
  <Grid item sx={6} sm={7} style={{ marginLeft: 'auto' }}>
    <img
      src={ isDesktop ? AnimasiProduk : AnimasiProduk2}
      style={{
        width: isDesktop ? '700px' : "300px",
        height: isDesktop ? '600px' : "300px",
        top: isDesktop ? "-60px" : '30px',
        left: isDesktop ? "400px" : '-500px',
        position: 'relative'
      }}
    />
  </Grid>
</Grid>


          {/* <a href="https://www.gambaranimasi.org/cat-lonceng-natal-1064.htm">
           <img src="https://www.gambaranimasi.org/data/media/1064/animasi-bergerak-lonceng-natal-0154.gif" border="0" alt="animasi-bergerak-lonceng-natal-0154" />
          </a> */}

         

          </div>

      </Header>
    </>
  );
};
