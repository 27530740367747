import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './component/home.js';
import { Produk } from './component/product';
import { HeaderComponent } from './component/header.js';
import { Artikel } from './component/artikel';
import { Event } from './component/event.js';
import { DetailArtikel } from './component/detail-artikel';
import { Form } from './component/form.js';
// import { Translate } from './component/Translate';

function App() {  
  return (
    <>
     {/* <Translate /> */}
      <BrowserRouter>
        <HeaderComponent />
         <Routes>
            <Route path='/'  element={<Home />}  />
            <Route path='detail-artikel/:id' element={< DetailArtikel />} />
            <Route path='/produk' element={<Produk />} />
            <Route path='/artikel' element={<Artikel />} />
            <Route path='/event' element={<Event />}/>
            <Route path='/form' element={<Form />} />
         </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
