import React, {useState, useRef} from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import emailjs from '@emailjs/browser';
import FormBackground from '../image/background-form2.jpg.png';
import { Card, Typography, message } from "antd";
import { Button, Container } from "@mui/material";
import { useTranslation } from "react-i18next";  
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import LogoGunung from '../image/logo-asam-jawa.png';
import PlaceIcon from '@mui/icons-material/Place';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import { ThreeCircles } from 'react-loader-spinner';
import TextArea from 'antd/es/input/TextArea';
import { FormControl, FormGroup, TextField } from '@mui/material';
import { Facebook, Instagram, Twitter, YouTube } from "@mui/icons-material";


export const Form = () => {

    const theme = useTheme();
    const formData = useRef();
    const [isRinging, setIsRinging] = useState(false);
    const { t, i18n } = useTranslation("global");
    const [isLoading, setIsLoading] = useState(false);
    const [nama, setNama] = useState('');
    const [email, setEmail] = useState('');
    const [kota, setKota] = useState([]);
    const [telepon, setTelepon] = useState('');
    const [detail, setDetail] = useState('');
    const [messageApi, contextHolder] = message.useMessage();
    const key = 'updatable';


    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
      });

      const sendEmail = (e) => {
        e.preventDefault();
        // Set isLoading to true when sending the email
        setIsLoading(true);
      
        // Validate the form before sending the email
        const isValid = validateForm();
      
        if (isValid) {
          emailjs
            .sendForm('service_3ukh7m5', 'template_jk10byr', formData.current, '6l0tizyMRcFgGbKIg')
            .then((result) => {
              console.log(result.text);
              // Reset the form
              e.target.reset();
              // Move the openMessage call here
              openMessage();
            })
            .catch((error) => {
              console.log(error.text);
              // Set isLoading to false when there is an error
              setIsLoading(false);
            })
            .finally(() => {
              // Set isLoading to false when the email sending is complete (whether success or error)
              setIsLoading(false);
            });
        } else {
          // Set isLoading to false when the form is not valid
          setIsLoading(false);
        }
      };

      const openMessage = () => {
        if (validateForm()) {
            messageApi.open({
              key,
              type: 'loading',
              content: 'Loading...',
            });
            setTimeout(() => {
              messageApi.success('Pesan Anda Terkirim', 2);
              setTimeout(() => {
                window.location.replace('/');
              }, 1000);
            }, 3000);
          }
      };

      const validateForm = () => {
        const newError = { ...error };
        let valid = true;
    
        if (!nama) {
          newError.nama = t("validasi-nama.text");
          valid = false;
        } else {
          newError.nama = "";
        }
    
        if (!email) {
          newError.email = t("validasi-email.text");
          valid = false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
          newError.email = 'Email tidak valid';
          valid = false;
        } else {
          newError.email = '';
        }
    
        if (!telepon) {
          newError.telepon = t("validasi-telepon.text");
          valid = false;
        } else {
          newError.telepon = '';
        }
    
        if (kota.length === 0) {
          newError.kota = t("validasi-kota.text");
          valid = false;
        } else {
          newError.kota = '';
        }
    
        if (!detail) {
          newError.detail = t("validasi-pesan.text");
          valid = false;
        } else {
          newError.detail = '';
        }
    
        setError(newError);
    
        return valid;
    
      }

      const [error, setError] = useState({
        nama: "",
        email: "",
        telepon: "",
        kota: "",
        detail: ""
      })

      const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Warna latar belakang yang menggelapkan
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999, // Pastikan lebih tinggi dari elemen lain
      };


    return (
        <>

     <div
        style={{
            background: `url(${FormBackground}) center center / cover`,
            position: "relative",
            height: isDesktop ? "1200px" : '900px',
            width: isDesktop ? "800px" : '400px',
            top: isDesktop ? '200px': '100px',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 auto",
        }}
      >
        <Grid
          item
          xs={12}
          sm={isDesktop ? 6 : 10}
          md={isDesktop ? 3 : 3}
          marginTop={!isDesktop ? "-20px" : 0}
          lg={!isDesktop ? 3 : 0}
          xl={!isDesktop ? 3 : 0}
          marginLeft={isDesktop ? 55 : 0}
        >
          <Container sx={{ 
            marginTop: !isDesktop ? -22 : -30, 
            width: "100%", 
            position: 'absolute', 
            right: isDesktop ? '1px': 0
            }}>
            {isLoading && (
              <div style={overlayStyle}>
                <ThreeCircles color="#FFA500" />
              </div>
            )}
            <Typography
              style={{
                textAlign: "center",
                paddingBottom: 20,
                fontSize: 20,
                fontFamily: "monospace",
              }}
            >
              {t("lengkapi.text")}
            </Typography>
            <form
              ref={formData}
              onSubmit={sendEmail}
              style={{ marginBottom: 130 }}
            >
              <TextField
                style={{ 
                width: "100%",
                }}
                id="outlined-name-input"
                label={t("nama.text")}
                name="nama"
                value={nama}
                onChange={(e) => setNama(e.target.value)}
                type="text"
                autoComplete="current-name"
              />
              {error.nama && (
                <div style={{ color: "red" }}>{error.nama}</div>
              )}
              <TextField
                sx={{
                  width: "100%",
                  mt: 2,
                }}
                id="outlined-email-input"
                label={t("email.text")}
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="current-email"
              />
              {error.email && (
                <div style={{ color: "red" }}>{error.email}</div>
              )}
              <TextField
                sx={{
                  width: "100%",
                  mt: 2,
                }}
                id="outlined-number-input"
                label={t("nomor.text")}
                type="number"
                name="telepon"
                value={telepon}
                onChange={(e) => setTelepon(e.target.value)}
                autoComplete="current-number"
              />
              {error.telepon && (
                <div style={{ color: "red" }}>{error.telepon}</div>
              )}
              <FormControl sx={{ mt: 2, width: "100%" }}>
                <TextField
                  sx={{
                    width: "100%",
                    mt: 2,
                  }}
                  id="outlined-number-input"
                  label={t("kota.text")}
                  type="text"
                  name="kota"
                  value={kota}
                  onChange={(e) => setKota(e.target.value)}
                  autoComplete="current-number"
                />
                {error.kota && (
                  <div style={{ color: "red" }}>{error.kota}</div>
                )}
                <TextArea
                  placeholder={t("pesan.text")}
                  style={{
                    marginTop: "20px",
                    height: "100px",
                    width: "100%",
                  }}
                  name="detail"
                  value={detail}
                  onChange={(e) => setDetail(e.target.value)}
                />
              </FormControl>
              {error.detail && (
                <div style={{ color: "red" }}>{error.detail}</div>
              )}
              {contextHolder}
              <Button
                onClick={openMessage}
                id="submit-data"
                type="submit"
                style={{
                  width: "100%",
                  marginTop: 20,
                  color: "white",
                  backgroundColor: "#a8c9a6",
                  fontFamily: "sans-serif",
                }}
              >
                {t("kirim.text")}
              </Button>
            </form>
          </Container>
        </Grid>
      </div>
           

         <Box
        component="footer"
        id="footer"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          [theme.breakpoints.up("sm")]: {
            p: 6,
            flexDirection: "row",
            textAlign: "left",
          },
          marginTop: isDesktop ? '450px' : '200px'
        }}
      >
        <Grid item xs={12} sm={3}>
          <div className="logo-container">
            <img
              src={LogoGunung}
              width={200}
              height={200}
              className="logo-container"
              style={{
                height: "auto",
                maxWidth: "100%",
              }}
            />
            {isDesktop ? (
              <Grid>
                <div className="logo-text">
                  {t("big-title-1.text")} <span className="gunung-text">{t("big-title-2.text")}</span>
                </div>
              </Grid>
            ) : null}
          </div>
        </Grid>
        <Container maxWidth="lg">
          <Grid container spacing={5} justifyContent="flex-end">
            <Grid item xs={12} sm={3}>
              <Typography
                style={{
                  paddingBottom: "10px",
                  fontWeight: "bold",
                }}
                variant="h6"
                color="text.primary"
                gutterBottom
              >
                 {t("tentang.text")}
                <hr />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                 {t("list-tentang.text")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                variant="h6"
                style={{
                  paddingBottom: "10px",
                  fontWeight: "bold",
                }}
                color="text.primary"
                gutterBottom
              >
                 {t("title-kontak.text")}
                <hr />
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ paddingTop: "9px" }}
              >
                <PlaceIcon
                  style={{
                    animation: isRinging
                      ? "ringing 1s infinite alternate"
                      : "none",
                  }}
                />
                <div style={{ width: "100%" }}>
                  <Typography
                    style={{
                      textAlign: !isDesktop ? "center" : "left",
                    }}
                  >
                    {t("detail-kontak.text")}
                  </Typography>
                </div>
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ paddingTop: "30px" }}
              >
                <MailIcon
                  style={{
                    animation: isRinging
                      ? "ringing 1s infinite alternate"
                      : "none",
                  }}
                />
                <Typography
                  style={{
                    marginTop: "-10px",
                    paddingTop: "9px",
                  }}
                >
                  <span
                    style={{
                      paddingRight: "5px",
                      paddingLeft: "5px",
                    }}
                  >
                    tamarindogroup3@gmail.com
                  </span>
                  <br />
                  <span
                    style={{
                      paddingLeft: isDesktop ? "5px" : 0,
                    }}
                  >
                    {t("or.text")}
                  </span>
                  <br />
                  <span
                    style={{
                      paddingRight: "10px",
                      paddingLeft: "5px",
                    }}
                  >
                    tamarindotbn@gmail.com
                  </span>
                </Typography>
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ paddingTop: "30px" }}
              >
                <PhoneIcon
                  style={{
                    marginRight: "5px",
                    animation: isRinging
                      ? "ringing 1s infinite alternate"
                      : "none",
                  }}
                />
                <Typography>
                  <span
                    style={{
                      paddingRight: "10px",
                      paddingLeft: "5px",
                    }}
                  >
                    081133388188
                  </span>
                </Typography>
              </Typography>
              <Typography
                style={{
                  marginTop: "20px",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {t("daftar-agen.text")}
                </span>
                <br />
                <span>081317779733</span>
              </Typography>
              <Typography
                style={{
                  marginTop: "20px",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {t("support.text")}
                </span>
                <br />
                <span>
                  <a
                    href="https://www.instagram.com/asamjawagunung/?hl=en"
                    style={{
                      color: "blue",
                    }}
                  >
                    @asamjawagunung
                  </a>
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                variant="h6"
                color="text.primary"
                style={{
                  paddingBottom: "10px",
                  fontWeight: "bold",
                }}
                gutterBottom
              >
                {t("follow.text")}
                <hr />
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ paddingTop: "9px" }}
              >
                <a href='https://www.facebook.com/asamjawacapgunung/'>
                <Facebook
                  style={{
                    color: "#4267B2",
                    fontSize: "30px",
                    marginRight: isDesktop ? "10px" : "30px",
                  }}
                />
                </a>
                <a href='https://twitter.com/Bintang77542150/status/994067149739188224'>
                <Twitter
                  style={{
                    color: "#1DA1F2",
                    fontSize: "30px",
                    marginRight: isDesktop ? "10px" : "30px",
                  }}
                />
                </a>
                <a href="https://www.instagram.com/asamjawagunung/"  >
                <Instagram
                  style={{
                    color: "#C13584",
                    fontSize: "30px",
                    marginRight: isDesktop ? "10px" : "30px",
                  }}
                />
                </a>
                <a href='https://www.youtube.com/channel/UCBkmg3iBjBEan-wk2pMrzXg'>
                <YouTube
                  style={{
                    color: "#FF0000",
                    fontSize: "30px",
                  }}
                />
                </a>
              </Typography>
              <Typography
                style={{
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "5px",
                  }}
                >
                  {t("stay.text")}
                </Typography>
                <Typography>
                  {t("list-stay.text")}
                </Typography>
              </Typography>
              <Typography>
                © {new Date().getFullYear()} {t("title-footer.text")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
        </>
    )
}